.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
  background-color: var(--white-text-color);
}

.modalOpen {
  animation: openModal 0.4s ease-in-out forwards;
}

.modalClose {
  animation: closeModal 0.6s ease-in-out forwards;
}

.scrollableModal {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  background-color: var(--white-text-color);
  height: 80vh;
  overflow: scroll;
}

.header {
  display: flex;
  align-items: center;
  color: var(--white-text-color);
  justify-content: center;
  background: var(--unnamed-color-161557);
  padding: 1rem;
}

.header h2 {
  margin: 0;
}

.content {
  padding: 1rem;
  overflow-y: auto;
}

.scrollableContent {
  padding: 1rem;
  overflow-y: auto;
}

.actions {
  padding: 1rem;
  display: flex;
  gap: var(--gap-s);
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

@keyframes openModal {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes closeModal {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
  }
}
