body {
  margin: 0;
  letter-spacing: 0.15px;
  background-color: #f6f8fc;
  color: var(--unnamed-color-01046b);
}

:root {
  /* Colors: */
  --no-info: #c3c3c3;
  --not-working: #51586b;
  --low: #ba1f3b;
  --mid: #fc8711;
  --high: #47ac34;
  --white-text-color: #ffffffff;
  --unnamed-color-240912: #240912;
  --unnamed-color-16155: #161557;
  --unnamed-color-161557: #0731e0;
  --illustrations-secondary: #d3d1d1;
  --unnamed-color-bd37b71a: #bd37b71a;
  --unnamed-color-00000099: #00000099;
  --unnamed-color-000000de: #000000de;
  --unnamed-color-00000061: #00000061;
  --unnamed-color-000000bd: #000000bd;
  --unnamed-color-bd37b7: #bd37b7;
  --unnamed-color-01046b: #01046b;
  --unnamed-color-0731e0: #0731e0;
  --illustrations-secondary: #f8eaf8;
  --unnamed-color-bd37b71a: #bd37b71a;
  --unnamed-color-00000099: #00000099;
  --unnamed-color-000000de: #000000de;
  --unnamed-color-000000bd: #000000bc;
  --unnamed-color-00000061: #00000061;
  --unnamed-color-bd37b7: #bd37b7;

  --construc: #3d59e9;
  --bg-color: #ffffff;
  --main-bg-color: #f6f8fc;
  --border-color: #c1c1c1;
  --part-status-bg-color: #240912;
  --state-bg-color: #d3d1d1;
  --prediction: #ebad2c;
  --prediction-txt: #ebad2c;
  --reporting: var(--high);
  --reporting-txt: var(--high);
  --fault: var(--low);
  --fault-txt: var(--low);
  --ExternalNetwork: #3d59e9;
  --Batteries: #ebad2c;
  --Generator: #bd37b7;
  --RightLock: #01046b;
  --LeftLock: #512da8;
  --Communication: #004d40;
  --Pistons: #00e5ff;
  --Hydraulics: #827717;
  --Pump: #ba68c8;

  /* Font/text values */
  --unnamed-font-family-noto-sans-english: Arial, sans-serif;
  --unnamed-font-family-noto-sans-hebrew: noto-sans-hebrew-semi-cond, sans-serif;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-size-10: 10px;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-34: 34px;
  --unnamed-font-size-48: 48px;
  --unnamed-font-size-60: 60px;
  --unnamed-font-size-96: 96px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-4: 0.4px;
  --unnamed-character-spacing-0-25: 0.25px;
  --unnamed-character-spacing-0-15: 0.15px;
  --unnamed-character-spacing-0-14: 0.14px;
  --unnamed-character-spacing-0-5: 0.5px;
  --unnamed-character-spacing-0-18: 0.18px;
  --unnamed-character-spacing-1-5: 1.5px;
  --unnamed-character-spacing-1-25: 1.25px;
  --unnamed-character-spacing-0-1: 0.1px;
  --unnamed-character-spacing--0-48: -0.48px;
  --unnamed-character-spacing--1-44: -1.44px;
  --unnamed-line-spacing-16: 16px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-36: 36px;
  --unnamed-line-spacing-56: 56px;
  --unnamed-line-spacing-72: 72px;
  --unnamed-line-spacing-112: 112px;
  --unnamed-text-transform-uppercase: uppercase;

  --gap: 20px;
  --ml-gap: 16px;

  --big-screen-size: 1228px;

  --drawer-width: 238px;
  --topBar-height: 93px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-15);
  color: var(--unnamed-color-000000bd);
}
.unnamed-character-style-2 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-0-4);
  color: var(--unnamed-color-00000099);
}
.unnamed-character-style-3 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-15);
  color: var(--unnamed-color-00000099);
}
.unnamed-character-style-4 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-1-25);
  color: var(--unnamed-color-00000061);
  text-transform: var(--unnamed-text-transform-uppercase);
}
.unnamed-character-style-5 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-1-25);
  color: var(--unnamed-color-bd37b7);
  text-transform: var(--unnamed-text-transform-uppercase);
}
.unnamed-character-style-6 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-300);
  font-size: var(--unnamed-font-size-96);
  line-height: var(--unnamed-line-spacing-112);
  letter-spacing: var(--unnamed-character-spacing--1-44);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-7 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-300);
  font-size: var(--unnamed-font-size-60);
  line-height: var(--unnamed-line-spacing-72);
  letter-spacing: var(--unnamed-character-spacing--0-48);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-8 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-1);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-9 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-20);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-10 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-16);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-11 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-10);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-1-5);
  color: var(--unnamed-color-000000de);
  text-transform: var(--unnamed-text-transform-uppercase);
}
.unnamed-character-style-12 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-48);
  line-height: var(--unnamed-line-spacing-56);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-13 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-14 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-15);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-15 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-14);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-16 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-5);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-17 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-16);
  letter-spacing: var(--unnamed-character-spacing-1-25);
  color: var(--unnamed-color-000000de);
  text-transform: var(--unnamed-text-transform-uppercase);
}
.unnamed-character-style-18 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-34);
  line-height: var(--unnamed-line-spacing-36);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000de);
}

.unnamed-character-style-19 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--unnamed-color-000000de);
}
.unnamed-character-style-20 {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--unnamed-color-000000de);
}

.title {
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-18);
  color: var(--unnamed-color-01046b);
}

.leaflet-container {
  width: 100%;
  height: 85%;
}

::-webkit-datetime-edit {
  padding: 0.5em;
}
::-webkit-datetime-edit-fields-wrapper {
  cursor: not-allowed;
}
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(9%) sepia(98%) saturate(2766%)
    hue-rotate(234deg) brightness(97%) contrast(128%);
}

.bold{
  font-weight: bold;
}